'use client';

import React from 'react';
import { useParams } from 'next/navigation';
import { toClassName } from 'helpers';

// import i18n from 'config/i18n';

import { TNextClientComponent } from 'types/next';

// import {
//   Dropdown,
//   DropdownButton,
//   DropdownItem,
//   DropdownMenu,
// } from 'components/Dropdown';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import { styles } from '.';
type TProps = {
  className?: string;
};
const LangPicker: TNextClientComponent<TProps> = props => {
  const {
    className
  } = props;
  const params = useParams();
  // const pathname = usePathname();

  // const languages = i18n.locales.map((location) => ({
  //   id: location,
  //   label: location,
  //   href: pathname?.replace(params?.lang as string, location),
  // }));

  return <div data-sentry-component="LangPicker" data-sentry-source-file="LangPicker.tsx">
      <Button outline className={toClassName(styles.button, className)} data-sentry-element="Button" data-sentry-source-file="LangPicker.tsx">
        {params?.lang}
        <Icon accessor="chevron-down" className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="LangPicker.tsx" />
      </Button>
      {/* <DropdownMenu>
        {languages.map((item) => (
          <DropdownItem key={item.id} href={item.href} className={styles.item}>
            {item.label}
          </DropdownItem>
        ))}
       </DropdownMenu> */}
    </div>;
};
export default LangPicker;