'use client';

import React, { FC } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { toClassName } from 'helpers';
import { useTranslation } from 'hooks/useTranslation';
import IconElement from 'components/Icon/IconElement';
import { styles } from '.';
type TProps = {
  routes: {
    id: string;
    href: string;
    label: string;
  }[];
  socials: any[];
  lang: string;
  handleClose: () => void;
  setIsOpen: (isOpen: boolean) => void;
};
const SlideOverContent: FC<TProps> = props => {
  const {
    lang,
    routes,
    socials,
    handleClose,
    setIsOpen
  } = props;
  const {
    t
  } = useTranslation(lang);
  const pathname = usePathname();
  const CloseIcon = () => {
    return <div className={styles.closeIcon.container} data-sentry-component="CloseIcon" data-sentry-source-file="SlideOverContent.tsx">
        <div className={toClassName(styles.closeIcon.leg, 'rotate-45')} style={{
        height: '1px'
      }} />
        <div className={toClassName(styles.closeIcon.leg, '-rotate-45')} style={{
        height: '1px'
      }} />
      </div>;
  };
  return <div className={styles.container} data-sentry-component="SlideOverContent" data-sentry-source-file="SlideOverContent.tsx">
      <div className={styles.head} onClick={() => {
      handleClose();
      setIsOpen(false);
    }}>
        <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="SlideOverContent.tsx" />
        <p className={styles.closeIcon.label}>{t('label.file.close')}</p>
      </div>
      <ul className={styles.body}>
        {routes.map(route => <li key={route.id} className={toClassName(styles.route.base, pathname?.split('/')[2] === route.id || pathname?.split('/')[2] === undefined && route.id === 'home' ? styles.route.active : styles.route.inactive)} onClick={() => handleClose()}>
            {pathname?.split('/')[2] === route.id || pathname?.split('/')[2] === undefined && route.id === 'home' ? route.label : <Link href={route.href}>
                {route.id === 'jobs' ? <p>
                    {route.label}
                    <span className="text-3xl ml-2">(2)</span>
                  </p> : route.label}
              </Link>}
          </li>)}
      </ul>
      <div className={styles.foot}>
        {socials?.map(social => <a key={social.id} href={social.href} target="_blank">
            <IconElement type="brands" accessor={social.accessor as IconName} />
          </a>)}
      </div>
    </div>;
};
export default SlideOverContent;