'use client';

import React from 'react';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { TNextClientComponent } from 'types/next';
import ContainerLayout from 'components/Container/Container';
import Icon from 'components/Icon';
import { styles } from '.';
type TProps = {
  socials: {
    accessor: string;
    href: string;
  }[];
};
const Footer: TNextClientComponent<TProps> = props => {
  const {
    socials
  } = props;
  const params = useParams();
  const pathname = usePathname();
  const {
    t
  } = useTranslation();
  return <div className={styles.container} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      {pathname !== `/${params.lang}/contact` && <div className={styles.head}>
          <ContainerLayout type="center">
            <div className="flex items-center justify-center">
              <Link href="/contact">
                <p className={styles.title}>
                  {t('component.footer.label.cta')}
                </p>
              </Link>
            </div>
          </ContainerLayout>
        </div>}
      <div className={styles.body.container}>
        <ContainerLayout type="center" data-sentry-element="ContainerLayout" data-sentry-source-file="Footer.tsx">
          <div className={styles.body.content}>
            <p className="opacity-40 font-nbi-mono text-center tracking-display text-[0.6rem]">
              {t('label.custom.credits', {
              year: new Date().getFullYear()
            })}
            </p>
            <ul className={styles.list}>
              {socials?.map(social => <li key={social.accessor}>
                  <a href={social.href} target="_blank" className={styles.icon} aria-label={social.accessor}>
                    <Icon type="brands" accessor={social.accessor as IconName} />
                  </a>
                </li>)}
            </ul>
          </div>
        </ContainerLayout>
      </div>
    </div>;
};
export default Footer;